@tailwind base;
@tailwind components;
@tailwind utilities;


/* Inside App.css or index.css */

.text-edge-outline {
  -webkit-text-stroke: 1px hsla(0,0%,100%,.3);
}

/* Slide transition wrapper */
.transition-wrapper {
  position: relative;
  overflow: hidden;
}

.slide-transition {
  animation: slide-in 0.5s forwards;
}

/* Slide In Animation */
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Slide Out Animation */
.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from { opacity: 0; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes slide-from-right {
  from { transform: translateX(30px); }
}

@keyframes slide-to-left {
  to { transform: translateX(-30px); }
}

::view-transition-old(root) {
  animation: 0.5s cubic-bezier(0.4, 0, 1, 1) both fade-out,
    1s cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 0.5s cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    1s cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}